






import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import PostmanReport from '@/components/postman/PostmanReport.vue';

@Component({
  components: {
    PostmanReport,
  },
})
export default class PostmanReportDataView extends Vue {
}
