































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import OverlayLoader from '../shared/OverlayLoader.vue';
import {
  PostmanReportModal,
  PostmanCollectionModel,
  PostmanEnvironmentModel,
} from '../../models';

@Component({
  components: {
    OverlayLoader,
  },
})

export default class PostmanList extends Vue {
  selectedCollection: PostmanCollectionModel = new PostmanCollectionModel();
  selectedEnvironment: PostmanEnvironmentModel = new PostmanEnvironmentModel();
  isLoading: boolean = false;

  @Watch('needRefresh')
  onStatusChange(value: string, old: string) {
    this.isLoading = true;
    this.$store.dispatch('loadPostmanReports')
      .then(() => {
        this.isLoading = false;
      });
  }

  get collections(): PostmanCollectionModel[] {
    return this.$store.state.postmanCollections
      .sort((a: PostmanCollectionModel, b: PostmanCollectionModel) => {
        return a.name < b.name
          ? -1
          : a.name > b.name
            ? 1
            : 0;
      });
  }

  get postmanEnvironments(): PostmanEnvironmentModel[] {
    return this.$store.state.postmanEnvironments
      .sort((a: PostmanEnvironmentModel, b: PostmanEnvironmentModel) => {
        return a.name < b.name
          ? -1
          : a.name > b.name
            ? 1
            : 0;
      });
  }

  get postmanReports(): PostmanReportModal[] {
    return this.$store.state.postmanReports;
  }


  setPostmanEnvironment(environment: PostmanEnvironmentModel): void {
    this.selectedEnvironment = environment;
  }

  saveAndLoadPostmanReports(): void {
    this.isLoading = true;
    const payload = new PostmanReportModal();
    payload.collectionUid = this.selectedCollection.uid;
    payload.environmentUid = this.selectedEnvironment.uid;
    payload.collectionName = this.selectedCollection.name;
    payload.environmentName = this.selectedEnvironment.name;
    this.$store.dispatch('savePostmanReport', payload)
      .then(() => {
        this.isLoading = false;
        this.$store.dispatch('loadPostmanReports');
      });
  }

  data() {
    return {
      rules: {
        required: (v: string) => !!v || 'Required.',
      },
    };
  }

  mounted(): void {
    this.isLoading = true;
    Promise.all([
      this.$store.dispatch('loadPostmanCollections'),
      this.$store.dispatch('loadPostmanEnvironments'),
      this.$store.dispatch('loadPostmanReports'),
    ]).then(() => {
      this.isLoading = false;
    });
  }

  showReport(report: PostmanReportModal): string {
    return `../postman/report/${report.id}`;
  }
}

