







import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import PostmanList from '@/components/postman/PostmanList.vue';
import PostmanHeader from '@/components/postman/PostmanHeader.vue';

@Component({
  components: {
    PostmanHeader,
    PostmanList,
  },
})
export default class PostmanView extends Vue {
}
