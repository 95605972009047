











import Vue from 'vue';
import Component from 'vue-class-component';
import OverlayLoader from '../shared/OverlayLoader.vue';

@Component({
  components: {
    OverlayLoader,
  },
})

export default class PostmanList extends Vue {
  isLoading: boolean = false;

  htmlReport: string = '';
  mounted(): void {
    this.isLoading = true;
    const reportData = this.$route.params.id;
    this.htmlReport = `${process.env.VUE_APP_URL}/api/postman/html/${reportData}`;
  }
}

